<template>
    <div v-if="messages && messages.length > 0" :class="'alert ' + alertCssClass">
        <h4 v-if="title != ''">{{ title }}</h4>
        <ul class="no-margins">
            <li v-for="(message, index) in messages" :key="index">
                {{ message.formattedMessage }}
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { Message } from '@fwk-client/store/types';

@Component({
  components: {}
})
export default class Messages extends Vue {

  @Prop({
    type: Array,
    required: true
  }) readonly messages: Message[] | undefined

  @Prop({
    type: String,
    required: true
  }) readonly type: string | undefined

  get alertCssClass() {
    switch(this.type) {
      case 'error':
        return 'alert-danger';
      case 'success':
        return 'alert-success';
    }
    // We do not return any specific class when type is not recognized
    return '';
  }

  get localizedKeyPrefix() {
    switch(this.type) {
      case 'error':
        return 'error.';
      case 'success':
        return 'success.';
    }
    // We do not return any specific prefix when type is not recognized
    return '';
  }

  get title() {
    if(this.messages) {
      if(this.messages.length == 1 && this.$te(this.localizedKeyPrefix + 'title-one')) {
        return this.$t(this.localizedKeyPrefix + 'title-one');
      }
      else if(this.messages.length > 1 && this.$te(this.localizedKeyPrefix + 'title-several')) {
        return this.$t(this.localizedKeyPrefix + 'title-several');
      }
    }
    return '';
  }

}
</script>
