<template>
  <section id="content">
    <PageTitle :title="$t('intrastats_generate.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div v-if="showSuccessDeclaration">

        <div class="alert alert-success">
          <h4>{{ $t('intrastats_generate.success.title') }}</h4>
        </div>

        <div class="row generate-success">
          <div class="col-lg-12">
            <div class="ibox">
              <div class="ibox-content p-md">
                <div class="row">
                    <div class="col-lg-12 p-xs">
                      {{ $t('intrastats_generate.success.message') }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 p-xs">
                      {{ $t('intrastats_generate.success.psi-id') }}
                    </div>
                    <div class="col-lg-9 p-xs">
                      {{ generatedDeclaration.psiId }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 p-xs">
                      {{ $t('intrastats_generate.success.reference-period') }}
                    </div>
                    <div class="col-lg-9 p-xs">
                      {{ generatedDeclaration.formattedReferencePeriod }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 p-xs">
                      {{ $t('intrastats_generate.success.nb-lines') }}
                    </div>
                    <div class="col-lg-9 p-xs">
                      {{ generatedDeclaration.nbLines }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 p-xs">
                      {{ $t('intrastats_generate.success.download-xsl') }}
                    </div>
                    <div class="col-lg-9 p-xs">
                      <a :href="getDownloadURL(generatedDeclaration.XLSFile)" target="_blank"><i class="fa fa-file-excel-o"></i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 p-xs">
                      {{ $t('intrastats_generate.success.download-xml') }}
                    </div>
                    <div class="col-lg-9 p-xs">
                      <a :href="getDownloadURL(generatedDeclaration.XMLFile)" target="_blank"><i class="fa fa-file-code-o"></i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 p-xs">
                      <a class="btn btn-primary" href="javascript:void(0)" @click="restartGenerate()">{{ $t('intrastats_generate.success.new-form') }}</a>
                    </div>
                </div>
                <p>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-else>

        <Messages :messages="errors" type="error"></Messages>
        <Messages :messages="successes" type="success"></Messages>

        <div class="row">
          <div class="col-lg-12">
            <div class="ibox">
              <div class="ibox-content p-md">

                <form role="form" @submit="onFormSubmit" name="generateForm">
                  <div class="form-group row">
                      <label for="generateXMLEnvelopeId" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.envelope-id') }}</label>
                      <div class="col-lg-9">
                        <input id="generateXMLEnvelopeId" type="text" :placeholder="$t('intrastats_generate.form.envelope-id_placeholder')" class="form-control" v-model="form.envelopeId" required>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLPartyType" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.party-type') }}</label>
                      <div class="col-lg-9">
                        <select id="generateXMLPartyType" class="form-control" v-model="form.party.type" required>
                          <option value="PSI">{{ $t('intrastats_generate.form.party-type-psi') }}</option>
                          <option value="TDP">{{ $t('intrastats_generate.form.party-type-tdp') }}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLPartyRole" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.party-role') }}</label>
                      <div class="col-lg-9">
                        <select id="generateXMLPartyRole" class="form-control" v-model="form.party.role" required>
                          <option value="sender">{{ $t('intrastats_generate.form.party-role-sender') }}</option>
                          <option value="PSI">{{ $t('intrastats_generate.form.party-role-psi') }}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLPartyId" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.party-id') }}</label>
                      <div class="col-lg-9">
                        <input v-if="config.form.party.id.length == 0" id="generateXMLPartyId" type="text" :placeholder="$t('intrastats_generate.form.party-id_placeholder')" class="form-control" v-model="form.party.id" required>
                        <select v-if="config.form.party.id.length > 0" id="generateXMLPartyId" class="form-control" v-model="form.party.id" required>
                          <option v-for="(partyId, index) in config.form.party.id" :key="index" :value="partyId">{{partyId}}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLPartyName" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.party-name') }}</label>
                      <div class="col-lg-9">
                        <input v-if="config.form.party.name.length == 0" id="generateXMLPartyName" type="text" :placeholder="$t('intrastats_generate.form.party-name_placeholder')" class="form-control" v-model="form.party.name" required>
                        <select v-if="config.form.party.name.length > 0" id="generateXMLPartyName" class="form-control" v-model="form.party.name" required>
                          <option v-for="(partyName, index) in config.form.party.name" :key="index" :value="partyName">{{partyName}}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLReferencePeriod" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.reference-period') }}</label>
                      <div class="col-lg-5">
                        <select id="generateXMLReferencePeriod" class="form-control" v-model="form.referencePeriod.month" required>
                          <option value="01">{{ $t('months.january') }}</option>
                          <option value="02">{{ $t('months.february') }}</option>
                          <option value="03">{{ $t('months.march') }}</option>
                          <option value="04">{{ $t('months.april') }}</option>
                          <option value="05">{{ $t('months.may') }}</option>
                          <option value="06">{{ $t('months.june') }}</option>
                          <option value="07">{{ $t('months.july') }}</option>
                          <option value="08">{{ $t('months.august') }}</option>
                          <option value="09">{{ $t('months.september') }}</option>
                          <option value="10">{{ $t('months.october') }}</option>
                          <option value="11">{{ $t('months.november') }}</option>
                          <option value="12">{{ $t('months.december') }}</option>
                        </select>
                      </div>
                      <div class="col-lg-4">
                        <select class="form-control" v-model="form.referencePeriod.year" required>
                          <option v-for="(year, index) in config.form.referencePeriod.year" :key="index" :value="year">{{year}}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLPsiId" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.psi-id') }}</label>
                      <div class="col-lg-9">
                        <input id="generateXMLPsiId" type="text" :placeholder="$t('intrastats_generate.form.psi-id_placeholder')" class="form-control" v-model="form.psiId" required>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLDeclarationTypeCode" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.declaration-type-code') }}</label>
                      <div class="col-lg-9">
                        <select id="generateXMLDeclarationTypeCode" class="form-control" v-model="form.declarationTypeCode" required>
                          <option value="1">{{ $t('intrastats_generate.form.declaration-type-code-one') }}</option>
                          <option value="2">{{ $t('intrastats_generate.form.declaration-type-code-two') }}</option>
                          <option value="3">{{ $t('intrastats_generate.form.declaration-type-code-three') }}</option>
                          <option value="4">{{ $t('intrastats_generate.form.declaration-type-code-four') }}</option>
                          <option value="5">{{ $t('intrastats_generate.form.declaration-type-code-five') }}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLFlowCode" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.flow-code') }}</label>
                      <div class="col-lg-9">
                        <select id="generateXMLFlowCode" class="form-control" v-model="form.flowCode" required>
                          <option value="A">{{ $t('intrastats_generate.form.flow-code-A') }}</option>
                          <option value="D">{{ $t('intrastats_generate.form.flow-code-D') }}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label for="generateXMLFile" class="col-lg-3 col-form-label">{{ $t('intrastats_generate.form.file') }}</label>
                      <div class="col-lg-9">
                        <b-form-file
                          id="generateXMLFile"
                          v-model="form.file"
                          :state="Boolean(form.file)"
                          :placeholder="$t('intrastats_generate.form.file_placeholder')"
                          :drop-placeholder="$t('intrastats_generate.form.file_drop-placeholder')"
                          required
                        />
                      </div>
                  </div>
                  <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('intrastats_generate.form.button') }}</button>
                </form>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<style scoped>
  .generate-success .fa {
    font-size: 1.3em;
  }
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../../panels/PageTitle.vue';
import Messages from '../../panels/Messages.vue';
import { Message } from '@fwk-client/store/types';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {
    PageTitle,
    Messages
  }
})
export default class Generate extends mixins(GenericPage) {

  generatedDeclaration:any = null;

  config:any = {
    defaultValues : {
      envelopeId: '',
      party: {
          type: '',
          role: '',
          id: '',
          name: ''
      },
      referencePeriod: {
          month: '',
          year: '',
      },
      psiId: '',
      declarationTypeCode: '1',
      flowCode: 'A',
      file: null
    },
    form: {
      party: {
          id: [],
          name: []
      },
      referencePeriod: {
          year : []
      }
    }
  };

  form:any = {...this.config.defaultValues};

  errors:Message[] = [];
  successes:Message[] = [];

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=generateForm] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  created() {
    // We need to get the list of available roles for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/intrastats/generate-config', options).then((response:any) => {
      if(response.config) {  
        this.config = response.config;
        this.form = {...this.config.defaultValues};
      }
    });
  }

  onApiErrors(errors:Message[]) {
    this.errors = errors;
  }

  onApiSuccesses(successes:Message[]) {
    this.successes = successes
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var formData = new FormData();
    formData.append("envelopeId", this.form.envelopeId);
    formData.append("partyType", this.form.party.type);
    formData.append("partyRole", this.form.party.role);
    formData.append("partyId", this.form.party.id);
    formData.append("partyName", this.form.party.name);
    formData.append("referencePeriodMonth", this.form.referencePeriod.month);
    formData.append("referencePeriodYear", this.form.referencePeriod.year);
    formData.append("psiId", this.form.psiId);
    formData.append("declarationTypeCode", this.form.declarationTypeCode);
    formData.append("flowCode", this.form.flowCode);
    if(this.form.file != null) {
      formData.append("file", this.form.file!, (this.form.file! as File).name);  
    }

    var options:api.ApiVueOptions =  {
      app: this,
      errorsHandler: this.onApiErrors,
      successesHandler: this.onApiSuccesses
    }
    
    // We clear the current messages
    this.errors = [];
    this.successes = [];

    this.laddaSubmit!.start();

    api.postAPIFormData('/api/intrastats/generate', formData, options).then((response:any) => {
      
      this.laddaSubmit!.stop();
      
      if(response.generated) {
        this.form = {...this.config.defaultValues};
        this.generatedDeclaration = response.declaration;
      }
    });
  }

  get showSuccessDeclaration() {
    return this.generatedDeclaration != null;
  }

  getDownloadURL(file:any) {
    return '/api/intrastats/file/'+file._id+'/download';
  }

  restartGenerate() {
    this.form = {...this.config.defaultValues};
    this.generatedDeclaration = null;
  }

}
</script>